







































































// Vue
import Vue from "vue";
import { mapActions } from "vuex";
// Models
import { AuthUser } from "@/core/_models/authUser.model";
// API
import { UserClient, UserTokenRequest } from "@/core/api/PharmacyReimbursementApi";

const userClient = new UserClient();

export default Vue.extend({
  name: "Login",

  data() {
    return {
      isLoading: false,
      errorLogin: false,
      errorText: undefined as string | undefined,

      email: "",
      password: ""
    };
  },

  methods: {
    ...mapActions({ login: "login" }),

    async submitLogin(): Promise<void> {
      this.errorLogin = false;

      const payload = {
        username: this.email,
        password: this.password
      } as UserTokenRequest;

      try {
        this.isLoading = true;
        const response = await userClient.authenticate(payload);

        if (!response) {
          this.errorLogin = true;
          return;
        }

        const user: AuthUser = {
          accountID: response.accountID,
          email: response.email,
          firstName: response.firstName,
          lastName: response.lastName,
          accessToken: response.accessToken,
          role: response.role
        };

        // Commit user to state
        this.login(user);

        // user online
        await userClient.userOnline(user.accountID!);
      } catch (error: any) {
        this.errorLogin = true;

        if (error.status === 400 || error.status === 401) {
          this.errorText = "Invaid email or password. Please try again.";
        } else {
          this.errorText = "There was an error trying to log in. Please try again.";
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
});
